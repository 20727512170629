import React, { useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import ChatList from "./ChatList/ChatList";
import Payment from "./Payment/Payment";
import Sponsorship from "./Sponsorship/Sponsorship";
import "./ProfileTab.scss";
import DocList from "../../../Reusable/Modal/DocList/DocList";

const ProfileTab = ({ topSponsorship, sponsorshipsList }) => {
  const student = sponsorshipsList[0]?.beneficiary;
  const stringDetail = student?.detail;
  const detail =
    typeof stringDetail === "string" ? JSON.parse(stringDetail) : stringDetail;
  const [docList, setDocList] = useState(null);

  const studentApplication = detail?.fileCustom?.filter(
    (d) => d.Type__c === "Doc_Application" || d.Type__c === "Doc_Other"
  );
  const feedbackLetters = detail?.fileCustom?.filter(
    (d) => d.Type__c === "Doc_Feedback" || d.Type__c === "Doc_Receipts"
  );

  const [loaded, setLoaded] = useState(false);
  const handleSelect = (key) => {
    if (key === "COMMUNICATE") {
      setLoaded(true);
    } else {
      setLoaded(false);
    }
  };

  const total = Object.entries(sponsorshipsList).reduce((pv, cv) => {
    return (
      pv +
      cv[1].payments.reduce((p, c) => {
        return p + (c.amount - c.tip);
      }, 0)
    );
  }, 0);

  return (
    <div className="ProfileTab">
      <Tab.Container id="left-tabs-example" defaultActiveKey="PAYMENTS">
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link eventKey="UPDATES" onSelect={handleSelect}>
              UPDATES
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="COMMUNICATE" onSelect={handleSelect}>
              COMMUNICATE
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="PAYMENTS" onSelect={handleSelect}>
              PAYMENTS
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="SPONSORSHIP" onSelect={handleSelect}>
              SPONSORSHIP
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="UPDATES">
            <div className="download mb-4">
              <p>
                {student.firstName}'s Documents:{" "}
                <i className="fa fa-info-circle"></i>
              </p>
              {studentApplication?.length > 0 && (
                <div className="mt-2">
                  <button onClick={() => setDocList(studentApplication)}>
                    <i className="fa fa-file-text-o fa-document"></i> Student
                    Application
                  </button>
                </div>
              )}
              {feedbackLetters?.length > 0 && (
                <div className="mt-2">
                  <button onClick={() => setDocList(feedbackLetters)}>
                    <i className="fa fa-file-text-o fa-document"></i> Feedback
                    Letters
                  </button>
                </div>
              )}
            </div>
            <h2>
              <i className="fa fa-list-alt"></i> NEWS FEED
            </h2>
          </Tab.Pane>
          <Tab.Pane eventKey="COMMUNICATE">
            <h2>
              <i className="fa fa-comments-o"></i> COMMUNICATE
            </h2>
            <ChatList sponsorship={topSponsorship} loaded={loaded} />
          </Tab.Pane>
          <Tab.Pane eventKey="PAYMENTS">
            <h2>
              <i className="fa fa-dollar"></i> PAYMENTS
            </h2>
            {Object.entries(sponsorshipsList).map(([key, obj], i) => (
              <Payment index={i} sponsorship={obj} total={total} />
            ))}
          </Tab.Pane>
          <Tab.Pane eventKey="SPONSORSHIP">
            <h2>
              <i className="fa fa-list-alt"></i> SPONSORSHIP
            </h2>
            {Object.entries(sponsorshipsList).map(([key, obj], i) => (
              <Sponsorship index={i} sponsorship={obj} />
            ))}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      {docList && (
        <DocList list={docList} handleClose={() => setDocList(null)} />
      )}
    </div>
  );
};

export default ProfileTab;
