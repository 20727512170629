import React, { useState } from "react";
import { Button, InputGroup, FormControl, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { cancelSponsorship } from "../../../store/actions";
import { updateStatusSponsorship } from "../../../store/actions/sponsorships";

function SponsorshipHeader(props) {
  const [adminCancelationNote, setAdminCancelationNote] = useState("");
  const [adminComments, setAdminComments] = useState("");
  const [status, setStatus] = useState("");

  const { sponsorship } = props;
  const { beneficiary, user } = sponsorship;
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleCancel = () => {
    var confirm = window.confirm("Do you need to cancel this?");
    const data = {
      sponsorshipId: sponsorship.id,
      adminCancelationNote,
      adminComments,
    };
    if (confirm) {
      dispatch(cancelSponsorship(data, token));
    }
  };

  const handleUpdate = () => {
    if (status) {
      var confirm = window.confirm(
        `Do you need to update status to "${status}"?`
      );
      const data = {
        sponsorshipId: sponsorship.id,
        status,
      };
      if (confirm) {
        dispatch(updateStatusSponsorship(data, token));
      }
    } else {
      alert("Select status");
    }
  };

  let btnPanel = null;

  if (sponsorship.status !== "canceled") {
    btnPanel = (
      <>
        <div style={{ padding: "0 2rem", flex: 1 }}>
          <Row>
            <Col sm={6}>
              {" "}
              <InputGroup className="ml-3" width="100%">
                <InputGroup.Prepend>
                  <InputGroup.Text>Cancelation Note</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  onChange={(e) => setAdminCancelationNote(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={6}>
              <InputGroup className="ml-3" width="100%">
                <InputGroup.Prepend>
                  <InputGroup.Text>Admin Comments</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  onChange={(e) => setAdminComments(e.target.value)}
                />
              </InputGroup>
            </Col>
          </Row>
        </div>
        <Button variant="danger" className="cancelBtn" onClick={handleCancel}>
          Cancel Sponsorship
        </Button>
      </>
    );
  } else {
    btnPanel = (
      <div className="ml-auto small d-flex">
        <div style={{ padding: "0 2rem", flex: 1 }}>
          <InputGroup className="ml-3" width="100%">
            <InputGroup.Prepend>
              <InputGroup.Text>Status</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              as="select"
              name="status"
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">Select</option>
              <option value="active">Active</option>
              <option value="pending">Pending</option>
            </FormControl>
          </InputGroup>
        </div>
        <Button variant="success" className="cancelBtn" onClick={handleUpdate}>
          Update
        </Button>
      </div>
    );
  }
  return (
    <div className="SponsorshipHeader">
      <div className="HeaderTextCMS">
        <strong>{user.firstName}</strong>&nbsp;sponsored to&nbsp;
        <strong>{beneficiary?.firstName}</strong>
        <div className="Status">
          <span className={sponsorship.status}>{sponsorship.status}</span>
        </div>
        {btnPanel}
      </div>
    </div>
  );
}

export default SponsorshipHeader;
