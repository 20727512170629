import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import LogoIco from "../../../../assets/elf-logo-clr.png";
import { Button, ButtonBlue } from "../../Button/Button";

import "./ConfirmModal.scss";

function ConfirmModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose} className="ConfirmModal">
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="TitleContent">
            <h5>Educate Lanka</h5>
            <h2>{props.title} </h2>
            <small>{props.description}</small>
            <img src={LogoIco} width="50" alt="Logo" />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
         <Row>
          <Col md={12} className="mt-3  text-center">
          MONTHLY AMOUNT
          <div className="sponsorshipAmountHandleArea">
           
            <button className="subtract" disabled={+props.studentAmount===+props.amount} onClick={()=>props.handleAmount("-")}><i className="fa fa-minus" aria-hidden="true"></i></button>
            ${props.amount}
            <button className="add" disabled={+props.amount === 30}  onClick={()=>props.handleAmount("+")}><i className="fa fa-plus" aria-hidden="true"></i></button>
           
          </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-3  text-center">
            <p>
              I agree to {" "}<Link to="/sonsorship/relationship-agreement" target="_blank">Student-Sponsor Relationship Agreement </Link>
              and{" "}
              <Link to="/sonsorship/agreement" target="_blank">Sponsorship Commitment Agreement.</Link>
              
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={6}>
            <ButtonBlue title="Cancel" onClick={props.handleClose} full />
          </Col>
          <Col sm={6}>
            <Button title="Confirm" onClick={props.handleConfirm} full />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmModal;
