/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import NotFound from "../../components/Reusable/NotFound";
import "./Birthday.scss";
import BirthdayHeader from "../../components/Birthday/BirthdayHeader/BirthdayHeader";
import * as actionsCreators from "../../store/actions";
import Loading from "../../components/Reusable/Loading/Loading";
import { useSelector } from "react-redux";
import PageSections from "../../components/Reusable/PageSections/PageSections";
import SharedModal from "../../components/Reusable/Modal/SharedModal/SharedModal";
import { AnchorButton } from "../../components/Reusable/Button/Button";
import { daysUntilBirthday } from "../../utils/func";
import moment from "moment";
import { toast } from "react-toastify";

function Birthday(props) {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.pages);
  const { token, me } = useSelector((state) => state.auth);
  const page = list?.find((p) => p.group === "/pledge-your-birthday");

  const [hideModal, setHideModal] = useState(false);
  const [tempConfirm, setTempConfirm] = useState(false);
  const [data, setData] = useState({
    month: "",
    year: "",
    date: "",
  });
  const [tempUpdateProfile, setTempUpdateProfile] = useState(false);

  useEffect(() => {
    if (tempConfirm && token && props.auth.me && !tempUpdateProfile) {
      if (!props.auth.me.data.birthday) {
        setTempUpdateProfile(true);
        const details = {
          ...props.auth.me.data,
          pledge: true,
          birthday: moment.utc(`${data?.month}-${data?.date}-${data?.year}`)._d,
        };
        props.editUser(details, props.auth.token);
        setHideModal(true);
      }else{
        toast.success("You have already pledged your birthday");
      }
    }
  }, [token, tempConfirm, props.auth.me]);

  const handleBirthdaySubmit = (date) => {
    if (props.auth.token && props.auth.me) {
      const data = { ...props.auth.me.data, pledge: true, birthday: date };
      props.editUser(data, props.auth.token);
      setHideModal(true);
    } else {
      setTempConfirm(true);
      dispatch(actionsCreators.loginModalHandle(true));
    }
  };

  if (page) {
    return (
      <div className="Birthday">
        {props.auth.meLoading && <Loading />}

        <BirthdayHeader
          section={page?.pageSections.find(
            (s) => s.section.key === "pledge-your-birthday"
          )}
          handleBirthdaySubmit={handleBirthdaySubmit}
          setHideModal={setHideModal}
          data={data}
          setData={setData}
        />
        <PageSections pageSections={page.pageSections} />

        {hideModal && (
          <SharedModal onHide={() => setHideModal(false)} show={hideModal}>
            {daysUntilBirthday(me?.data?.birthday) <= 30 ? (
              <p className="text-align-center">
                Hi {me?.data?.firstName},{" "}thank you so much for pledging your
                birthday for education! It looks like your birthday is only{" "}
                <strong>{daysUntilBirthday(me?.data?.birthday)}</strong> days
                away.{" "}
              </p>
            ) : (
              <>
                <p className="text-align-center">
                  Hi {me?.data?.firstName},{" "}thank you so much for pledging your
                  birthday for education! It looks like your birthday is more
                  than <strong>30</strong> days away.
                </p>
                <p className="text-align-center">
                  We will email you <strong>30</strong> days prior to your
                  birthday to remind you to start your fundraising campaign.
                </p>
              </>
            )}

            <Row className="justify-content-center mb-3">
              {daysUntilBirthday(me?.data?.birthday) <= 30 ? (
                <AnchorButton
                  size="sm"
                  to="/create-campaign"
                  title={"Create my campaign now"}
                />
              ) : (
                <div onClick={() => setHideModal(false)}>
                  <AnchorButton
                    size="sm"
                    title="Remind me closer to my birthday"
                  />
                </div>
              )}
            </Row>

            <div className="text-center mb-3">
              {daysUntilBirthday(me?.data?.birthday) <= 30 ? (
                <Link onClick={() => setHideModal(false)}>
                  No, I will create the campaign later
                </Link>
              ) : (
                <Link to="/create-campaign">
                  No, I will create the campaign now
                </Link>
              )}
            </div>
          </SharedModal>
        )}
      </div>
    );
  } else {
    return <NotFound />;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  editUser: (data, token) => dispatch(actionsCreators.editUser(data, token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Birthday);
