import React from "react";
import { OldSocialLogin as SocialLogin } from "react-social-login";
import "./SocialButton.scss";
import { useGoogleLogin } from "@react-oauth/google";

const SocialLoginButton = (props) => {
  const handleFacebookLogin = (user, err) => {
    if (user?._token) {
      props.loginFbUser(user);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      props.loginGoogleUser(tokenResponse.access_token);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <div className="socialBtn">
      <SocialLogin
        provider="facebook"
        appId={process.env.REACT_APP_FB_ID}
        callback={handleFacebookLogin}
      >
        <button className="fb" onClick={handleFacebookLogin}>
          <i className="fa fa-facebook" aria-hidden="true"></i>
          Sign in with Facebook
        </button>
      </SocialLogin>

      <div>
        <button className="google" onClick={() => login()}>
          <span></span>
          Sign in with Gmail
        </button>
      </div>
    </div>
  );
};

export default SocialLoginButton;
