import React, { useState, useEffect, useMemo } from "react";
import { Modal, Row, Col, Alert } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import valid from "card-validator";

import { Button } from "../../Button/Button";
import { payWithStripe, resetPayment } from "../../../../store/actions";
import Loading from "../../Loading/Loading";
import useYupValidationResolver from "../../../../utils/useYupValidationResolver";
import "./QuickPay.scss";
import LogoIco from "../../../../assets/elf-logo-clr.png";
import addThousandSeparator from "../../../../utils/addThousandSeparator";

const QuickPay = (props) => {
  const {
    giftcard,
    poolfund,
    student,
    amount,
    tip,
    month,
    recurring,
    sponsorship,
    elf,
  } = props;

  const { token } = useSelector((state) => state.auth);
  const payment = useSelector((state) => state.payment);
  const dispatch = useDispatch();

  const validationSchema = useMemo(() => {
    const validateObj = {};
    validateObj.cardNumber = yup
      .string()
      .test(
        "test-number",
        "Credit Card number is invalid",
        (value) => valid.number(value).isValid
      )
      .required("Card number is required");
    validateObj.expireDate = yup
      .string()
      .test(
        "test-date",
        "Expire date is invalid",
        (value) => valid.expirationDate(value).isValid
      )
      .required("Expire date is required");
    validateObj.cvv = yup
      .string()
      .test("test-cvv", "CVV is invalid", (value) => valid.cvv(value).isValid)
      .required("CVV number is required");

    if (!token) {
      validateObj.firstName = yup.string().required("First name is required");
      validateObj.lastName = yup.string().required("Last name is required");
      validateObj.email = yup.string().email().required("Email is required");
    }
    if (giftcard) {
      validateObj.recipientName = yup
        .string()
        .required("Recipient name is required");
      validateObj.recipientEmail = yup
        .string()
        .email()
        .required("Recipient Email is required");
    }
    return yup.object(validateObj);
  }, []);
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    control,
  } = useForm({ resolver });

  const [data, setData] = useState(null);

  useEffect(() => {
    return () => dispatch(resetPayment());
  }, []);

  useEffect(() => {
    if (payment.data) {
      setData(payment.data);
    }
  }, [payment.data]);

  const handleDataFormSubmit = (data) => {
    console.log(data);
    const obj = data;

    if (giftcard) {
      obj.giftcard = giftcard;
      obj.amount = amount;
    }
    if (poolfund) {
      obj.poolfund = poolfund;
      obj.amount = amount;
      obj.beneficiaryId = 1;
      obj.type = "elf";
    }
    if (student) {
      obj.student = true;
      obj.amount = amount;
      obj.beneficiaryId = student;
      obj.type = "student";
      obj.month = month;
      obj.recurring = recurring;
      obj.trial_end = sponsorship?.dueDate;
    }
    if (!token) {
      obj.user = {};
      obj.user.firstName = data.firstName;
      obj.user.lastName = data.lastName;
      obj.user.email = data.email;
    }
    dispatch(payWithStripe({ ...obj, tip }));
  };
  // const recurringHandler = () => {
  //   setData({ ...data, recurring: !data?.recurring });
  // };
  let button = (
    <Button
      title={`Donate $${addThousandSeparator(amount)}  ${
        recurring ? `every ${month} month` : ""
      }`}
      full
      lg
    />
  );
  //GIFT CARD BUTTON
  if (giftcard) {
    button = (
      <Button
        title={`Donate $${addThousandSeparator(amount)} and send the gift card`}
        full
        lg
      />
    );
  }

  if (sponsorship) {
    button = (
      <Button
        title={`Pay $${addThousandSeparator(amount)}  
    ${
      recurring
        ? `every ${month === 12 ? 1 : month} ${
            month === 12 ? "year" : month === 1 ? "month" : "months"
          }`
        : `for ${month === 12 ? 1 : month} ${
            month === 12 ? "year" : month === 1 ? "month" : "months"
          }`
    }`}
        full
        lg
      />
    );
  }

  if (elf) {
    button = (
      <>
        <label
          onClick={props?.recurringHandler}
          className={`checkLabel ${recurring ? "recurring" : null}`}
        >
          Donate monthly
        </label>
        <Button
          title={`Donate $${addThousandSeparator(amount)}  ${
            recurring ? "monthly" : ""
          } `}
          full
          lg
        />
      </>
    );
  }

  let content = (
    <>
      {payment.error && <Alert variant="danger">Payment incompleted.</Alert>}
      <form onSubmit={handleSubmit(handleDataFormSubmit)}>
        {!token && (
          <Row>
            <Col md={6} className="inputContainer">
              {errors?.firstName?.message && (
                <p className="errorMessage">{errors.firstName.message}</p>
              )}
              <input
                placeholder="First name"
                type="text"
                {...register("firstName")}
              />
            </Col>

            <Col md={6} className="inputContainer">
              {errors?.lastName?.message && (
                <p className="errorMessage">{errors.lastName.message}</p>
              )}
              <input
                placeholder="Last name"
                name="Last Name"
                type="text"
                {...register("lastName")}
              />
            </Col>
            <Col md={12} className="inputContainer">
              {errors?.email?.message && (
                <p className="errorMessage">{errors.email.message}</p>
              )}

              <input placeholder="Email" type="text" {...register("email")} />
            </Col>
          </Row>
        )}
        {/* ======================GIFT CARD ONLY================ */}
        {giftcard && (
          <>
            <div className="hr"></div>
            <Row>
              <Col md={12} className="inputContainer">
                {errors?.recipientName?.message && (
                  <p className="errorMessage">{errors.recipientName.message}</p>
                )}
                <input
                  type="text"
                  placeholder="Recipient name"
                  {...register("recipientName")}
                />
              </Col>
              <Col md={12} className="inputContainer">
                {errors?.recipientEmail?.message && (
                  <p className="errorMessage">
                    {errors.recipientEmail.message}
                  </p>
                )}
                <input
                  type="text"
                  placeholder="Recipient email"
                  {...register("recipientEmail")}
                />
              </Col>
            </Row>
          </>
        )}
        {/* ======================END GIFT CARD ONLY================ */}
        <div className="hr"></div>
        <Row>
          <Col md={12} className="inputContainer icon">
            {errors?.cardNumber?.message && (
              <p className="errorMessage">{errors.cardNumber.message}</p>
            )}
            <input
              type="text"
              placeholder="Card number"
              {...register("cardNumber")}
            />
            <i className="fa fa-credit-card"></i>
          </Col>

          <Col md={6} className="inputContainer icon">
            {errors?.expireDate?.message && (
              <p className="errorMessage">{errors.expireDate.message}</p>
            )}
            <Controller
              name="expireDate"
              control={control}
              defaultValue=""
              rules={{
                required: "Expiration date is required",
                pattern: {
                  value: /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
                  message: "Invalid expiration date format",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <input
                  type="text"
                  placeholder="MM/YY"
                  value={value}
                  onChange={(e) => {
                    let inputValue = e.target.value.replace(/\D/g, "");
                    if (inputValue.length >= 3) {
                      inputValue =
                        inputValue.slice(0, 2) + "/" + inputValue.slice(2, 4);
                    }
                    onChange(inputValue);
                  }}
                  autoComplete="cc-exp"
                />
              )}
            />
            <i className="fa fa-calendar"></i>
          </Col>

          <Col md={6} className="inputContainer icon">
            {errors?.cvv?.message && (
              <p className="errorMessage">{errors.cvv.message}</p>
            )}
            <input type="text" placeholder="CVV" {...register("cvv")} />
            <i className="fa fa-lock"></i>
          </Col>
        </Row>

        {button}
      </form>
      <br />
      <br />
    </>
  );

  const modalHeaderContent = () => {
    if (giftcard) {
      return (
        <>
          <h2>Gift Card Donation</h2>
          <small>A gift towards a deserving student's education</small>
        </>
      );
    }
    if (sponsorship) {
      return (
        <>
          <h3>Student Sponsorship Payment</h3>
          <small>
            Investing in their future, one deserving student at a time
          </small>
        </>
      );
    } else {
      return (
        <>
          <h2>Educate Lanka Fund</h2>
          <small>
            To support micro-scholarships and learning opportunities
          </small>
        </>
      );
    }
  };

  return (
    <Modal {...props} className="QuickPay">
      {payment.loading && <Loading />}
      <Modal.Header closeButton className="pb-0">
        <Modal.Title>
          <div className="TitleContent">
            <h5>Educate Lanka</h5>
            {/* {elf ? <h2>Educate Lanka Fund</h2> : <h2>Gift Card Donation</h2>}
            {elf? <small>To support micro-scholarships and learning opportunities</small> : <small>A gift towards a deserving student's education</small>} */}
            {modalHeaderContent()}
            <img src={LogoIco} width="50" alt="logo" />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-4">{content}</Modal.Body>
    </Modal>
  );
};

export default QuickPay;
