/*eslint-disable*/
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetPasswordIsValidToken,
  resetPassword,
  forgetPasswordReset,
} from "../../store/actions";
import { useQuery } from "../../utils/func";

import "./PasswordReset.scss";

function PasswordReset() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const history = useNavigate();
  const params = useQuery();
  const id = params.get("id");
  const token = params.get("token");
  const paramObj = { id, token };
  const diapstch = useDispatch();
  const navigate = useNavigate();
  const { redirect } = useSelector((state) => state.forgetPassword);

  useEffect(() => {
    if (id && token) {
      diapstch(resetPasswordIsValidToken(paramObj));
    }
  }, [id, token]);
  useEffect(() => {
    if (redirect) {
      navigate("/");
      return () => diapstch(forgetPasswordReset());
    }
  }, [redirect]);
  const onSubmit = (e) => {
    diapstch(resetPassword({ ...paramObj, password: getValues("password") }));
  };

  return (
    <div className="PasswordReset">
      <h2>Reset password</h2>
      <div className="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="inputContainer sm icon">
            <label>New password</label>
            {errors.password && (
              <p className="errorMessage">{errors.password.message}</p>
            )}
            <input
              type="password"
              {...register("password", {
                required: {
                  value: true,
                  message: "Password required.",
                },
                minLength: {
                  value: 4,
                  message: "Min length should be at least 4 letter",
                },
              })}
            />
            <i className="fa fa-key"></i>
          </div>

          <div className="inputContainer sm icon">
            <label>Confirm new password</label>
            {errors.rePassword && (
              <p className="errorMessage">{errors.rePassword.message}</p>
            )}
            <input
              type="password"
              {...register("rePassword", {
                required: {
                  value: true,
                  message: "Confirm password required.",
                },
                minLength: {
                  value: 4,
                  message: "Min length should be at least 4 letter",
                },
                validate: (value) =>
                  value === getValues("password") ||
                  "The passwords do not match",
              })}
            />
            <i className="fa fa-key"></i>
          </div>
          <div className="text-center">
            <button className="blueButton">Create Campaing</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PasswordReset;
