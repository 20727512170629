/*eslint-disable*/
import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./SponsorPrivate.scss";
import TopProfileDetail from "../../components/SponsorPrivate/TopProfileDetail/TopProfileDetail";
import BottomProfileDetail from "../../components/SponsorPrivate/BottomProfileDetail/BottomProfileDetail";
import Loading from "../../components/Reusable/Loading/Loading";
import * as actionCreators from "../../store/actions";

const SponsorPrivate = (props) => {
  useEffect(() => {
    if (props.auth.token) {
      props.getAuthSponsor(props.auth.token);
    }
  }, [props.auth.token]);

  let content = null;
  if (props.auth.meLoading) {
    content = <Loading page />;
  }
  if (props.auth.meError) {
    content = <h1>Sponsor not found</h1>;
  }
  if (props.auth.me) {
    content = (
      <>
        <TopProfileDetail
          editUser={props.editUser}
          sponsor={props.sponsor}
          auth={props.auth}
        />
        {props.sponsor.data ? (
          <div className="ProfileDetaSection">
            <BottomProfileDetail sponsor={props.sponsor.data} />
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  return <div className="SponsorPrivate">{content}</div>;
};

const mapStateToProps = (state) => ({
  sponsor: state.sponsor,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getAuthSponsor: (id) => dispatch(actionCreators.getAuthSponsor(id)),
  editUser: (data, token) => dispatch(actionCreators.editUser(data, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SponsorPrivate);
