import React, { useState, useMemo, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import LogoIco from "../../../../assets/elf-logo-clr.png";
import { Button } from "../../Button/Button";

import "./CampaignPayment.scss";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import useYupValidationResolver from "../../../../utils/useYupValidationResolver";
import addThousandSeparator from "../../../../utils/addThousandSeparator";
import * as yup from "yup";
import valid from "card-validator";

const CampaignPayment = ({ ...props }) => {
  const { token } = useSelector((state) => state.auth);

  const [method, setMethod] = useState("cc");
  const [payAmount, setPayAmount] = useState(0);

  const handlePayAmount = (event) => {
    setPayAmount(event.target.value);
  };

  const validationSchema = useMemo(() => {
    const validateObj = {};
    validateObj.amount = yup
      .number()
      .required("Amount is required")
      .typeError("Amount must be a number");

    if (method === "cc") {
      console.log(method);
      validateObj.cardNumber = yup
        .string()
        .test(
          "test-number",
          "Credit Card number is invalid",
          (value) => valid.number(value).isValid
        )
        .required("Card number is required");

      validateObj.expireDate = yup
        .string()
        .test(
          "test-date",
          "Expire date is invalid",
          (value) => valid.expirationDate(value).isValid
        )
        .required("Expire date is required");
      validateObj.cvv = yup
        .string()
        .test("test-cvv", "CVV is invalid", (value) => valid.cvv(value).isValid)
        .required("CVV number is required");
    }

    if (!token) {
      validateObj.firstName = yup.string().required("First name is required");
      validateObj.lastName = yup.string().required("Last name is required");
      validateObj.email = yup.string().email().required("Email is required");
    }

    return yup.object(validateObj);
  }, [method]);
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    control,
  } = useForm({ resolver });

  const handleMethod = () => {
    if (method === "pp") {
      setMethod("cc");
    } else if (method === "cc") {
      setMethod("pp");
    }
  };

  const handleDataFormSubmit = (data) => {
    const obj = data;
    obj.method = method;
    props.handlePayment(data);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleModal}
      className="CampaignPayment"
    >
      {/* {props.payment.loading && <Loading />} */}
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="TitleContent">
            <h5>Educate Lanka</h5>
            <h2>Fundraising Campaigns</h2>
            <small>
              To support micro-scholarships and learning opportunities
            </small>
            <img src={LogoIco} width="50" alt="logo" />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(handleDataFormSubmit)}>
          {!token && (
            <Row>
              <Col md={6} className="inputContainer">
                {errors?.firstName?.message && (
                  <p className="errorMessage">{errors.firstName.message}</p>
                )}

                <input
                  placeholder="First name"
                  type="text"
                  {...register("firstName")}
                />
              </Col>

              <Col md={6} className="inputContainer">
                {errors?.lastName?.message && (
                  <p className="errorMessage">{errors.lastName.message}</p>
                )}

                <input
                  placeholder="Last name"
                  type="text"
                  {...register("lastName")}
                />
              </Col>
              <Col md={12} className="inputContainer">
                {errors?.email?.message && (
                  <p className="errorMessage">{errors.email.message}</p>
                )}

                <input placeholder="Email" type="text" {...register("email")} />
              </Col>
            </Row>
          )}
          <Row>
            <Col md={12} className="inputContainer icon amountInput">
              {errors?.amount?.message && (
                <p className="errorMessage">{errors.amount.message}</p>
              )}
              <input
                placeholder="Amount"
                type="text"
                {...register("amount")}
                onChange={handlePayAmount}
              />
              <i className="fa fa fa-usd" aria-hidden="true"></i>
            </Col>
          </Row>
          <div className="switch" onClick={handleMethod}>
            <span>
              <i
                className={`fa fa fa-credit-card ${
                  method === "cc" && "active"
                }`}
                aria-hidden="true"
              ></i>
              <i
                className={`fa fa fa-cc-paypal ${method === "pp" && "active"}`}
                aria-hidden="true"
              ></i>
            </span>
          </div>
          {method === "cc" && (
            <Row>
              <Col md={12}>
                <div className="inputContainer icon">
                  {errors?.cardNumber?.message && (
                    <p className="errorMessage">{errors.cardNumber.message}</p>
                  )}
                  <input
                    type="text"
                    name="cardNumber"
                    placeholder="Card number"
                    {...register("cardNumber")}
                  />
                  <i className="fa fa-credit-card"></i>
                </div>
              </Col>

              <Col md={6}>
                <div className="inputContainer icon">
                  {errors?.expireDate?.message && (
                    <p className="errorMessage">{errors.expireDate.message}</p>
                  )}
                  {/* <input
                    type="text"
                    name="expireDate"
                    placeholder="MM/YY"
                    {...register("expireDate")}
                    value={expiryDate}
                    onChange={handleExpiryDate}
                  />
                  <i className="fa fa-calendar"></i> */}
                  <Controller
                    name="expireDate"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Expiration date is required",
                      pattern: {
                        value: /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
                        message: "Invalid expiration date format",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="text"
                        placeholder="MM/YY"
                        value={value}
                        onChange={(e) => {
                          let inputValue = e.target.value.replace(/\D/g, "");
                          if (inputValue.length >= 3) {
                            inputValue =
                              inputValue.slice(0, 2) +
                              "/" +
                              inputValue.slice(2, 4);
                          }
                          onChange(inputValue);
                        }}
                        autoComplete="cc-exp"
                      />
                    )}
                  />
                  <i className="fa fa-calendar"></i>
                </div>
              </Col>
              <Col md={6}>
                <div className="inputContainer icon">
                  {errors?.cvv?.message && (
                    <p className="errorMessage">{errors.cvv.message}</p>
                  )}
                  <input type="text" placeholder="CVV" {...register("cvv")} />
                  <i className="fa fa-lock"></i>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={12} className="text-center">
              <div className="inputContainer icon">
                <label>
                  <input type="checkbox" {...register("anonymous")} /> Pay as
                  anonymous
                </label>
              </div>
            </Col>
          </Row>
          <div className="buttons">
            <Button
              title={`Donate $${payAmount != 0 ? payAmount : 0} via ${
                method === "pp" ? "Paypal" : "Credit/Debit card"
              }`}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CampaignPayment;
