import { useFormik } from "formik";

import React from "react";
import { FormGroup, FormLabel, FormControl, Button } from "react-bootstrap";
import basic from "../../../../assets/sections/basic.png";
import poolFundHeader from "../../../../assets/sections/pool-fund-header.png";
import homePageHeader from "../../../../assets/sections/home-page-header.png";
import contactHeader from "../../../../assets/sections/contact-header.png";
import giftCard from "../../../../assets/sections/gift-card.png";
import header from "../../../../assets/sections/header.png";
import pledgeYourBirthday from "../../../../assets/sections/pledge-your-birthday.png";
import template1 from "../../../../assets/sections/template-1.png";
import template2 from "../../../../assets/sections/template-2.png";
import template3 from "../../../../assets/sections/template-3.png";
import template4 from "../../../../assets/sections/template-4.png";
import template5 from "../../../../assets/sections/template-5.png";
import template6 from "../../../../assets/sections/template-6.png";
import template7 from "../../../../assets/sections/template-7.png";
import template8 from "../../../../assets/sections/template-8.png";
import template9 from "../../../../assets/sections/template-9.png";
import template10 from "../../../../assets/sections/template-10.png";
import template11 from "../../../../assets/sections/template-11.png";
import template12 from "../../../../assets/sections/template-12.png";
import template13 from "../../../../assets/sections/template-13.png";
import template14 from "../../../../assets/sections/template-14.png";
import template15 from "../../../../assets/sections/template-15.png";
import template16 from "../../../../assets/sections/template-16.png";
import template17 from "../../../../assets/sections/template-17.png";

const pageSections = [
  { name: "Meta Data", key: "meta-data" },
  { name: "Basic", key: "basic" },
  { name: "Home Page Header", key: "home-page-header" },
  { name: "Pool Fund Header", key: "educate-lanka-fund-header" },
  { name: "Contact Header", key: "contact-header" },
  { name: "Pledge Your Birthday Header", key: "pledge-your-birthday" },
  { name: "Gift Card Header", key: "gift-card" },
  { name: "Header", key: "header" },
  { name: "Template-1", key: "template-1" },
  { name: "Template-2", key: "template-2" },
  { name: "Template-3", key: "template-3" },
  { name: "Template-4", key: "template-4" },
  { name: "Template-5", key: "template-5" },
  { name: "Template-6", key: "template-6" },
  { name: "Template-7", key: "template-7" },
  { name: "Template-8", key: "template-8" },
  { name: "Template-9", key: "template-9" },
  { name: "Template-10", key: "template-10" },
  { name: "Template-11", key: "template-11" },
  { name: "Template-12", key: "template-12" },
  { name: "Template-13", key: "template-13" },
  { name: "Template-14", key: "template-14" },
  { name: "Template-15", key: "template-15" },
  { name: "Template-16", key: "template-16" },
  { name: "Template-17", key: "template-17" },
];

function AddForm({ handleFormSubmit, data, editMode }) {
  const formik = useFormik({
    initialValues: {
      name: "",
      key: pageSections[0].key,
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Required";
      }
      if (!values.key) {
        errors.key = "Required";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      handleFormSubmit(values);
      setSubmitting(false);
    },
  });
  let {
    errors,
    values,
    touched,
    initialValues,
    handleChange,
    handleBlur,
    isSubmitting,
    validate,
    onSubmit,
    handleSubmit,
  } = formik;

  if (editMode) {
    initialValues.name = data?.name;
    initialValues.key = data?.key;
  }

  let templateImage = null;
  switch (values.key) {
    case "basic":
      templateImage = basic;
      break;
    case "home-page-header":
      templateImage = homePageHeader;
      break;
    case "educate-lanka-fund-header":
      templateImage = poolFundHeader;
      break;
    case "contact-header":
      templateImage = contactHeader;
      break;
    case "template-1":
      templateImage = template1;
      break;
    case "template-2":
      templateImage = template2;
      break;
    case "template-3":
      templateImage = template3;
      break;
    case "template-4":
      templateImage = template4;
      break;
    case "template-5":
      templateImage = template5;
      break;
    case "template-6":
      templateImage = template6;
      break;
    case "template-7":
      templateImage = template7;
      break;
    case "template-8":
      templateImage = template8;
      break;
    case "template-9":
      templateImage = template9;
      break;
    case "template-10":
      templateImage = template10;
      break;
    case "template-11":
      templateImage = template11;
      break;
    case "template-12":
      templateImage = template12;
      break;
    case "template-13":
      templateImage = template13;
      break;
    case "template-14":
      templateImage = template14;
      break;
    case "template-15":
      templateImage = template15;
      break;
    case "template-16":
      templateImage = template16;
      break;
    case "template-17":
      templateImage = template17;
      break;
    case "header":
      templateImage = header;
      break;
    case "pledge-your-birthday":
      templateImage = pledgeYourBirthday;
      break;
    case "gift-card":
      templateImage = giftCard;
      break;
  }

  return (
    <>
      <div className="WhiteContent">
        <div>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>Section Type</FormLabel>
              <FormControl
                as="select"
                disabled={editMode}
                onChange={handleChange}
                onBlur={handleBlur}
                name="key"
                value={values.key}
              >
                {pageSections.map((sec, i) => (
                  <option key={i} value={sec.key}>
                    {sec.name}
                  </option>
                ))}
              </FormControl>
              {errors.key && touched.key && errors.key}
            </FormGroup>
            <FormGroup>
              <FormLabel>Friendly Name</FormLabel>
              <FormControl
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              {errors.name && touched.name && errors.name}
            </FormGroup>

            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </form>
        </div>
      </div>
      <div className="WhiteContent mt-5">
        <img src={templateImage} alt="" width="100%" />
      </div>
    </>
  );
}

export default AddForm;
